// Modules
import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { document } from "browser-monads";
import { useSize } from "ahooks";
import { useLocation } from "react-use";

// Components
import Link from "../link";
import ShareComponent from "../shareComponent";

// Constants
import BREAKPOINTS from "../../constants/breakpoints";
import COLORS from "../../constants/colors";

// Styles
import * as styles from "./sideMenu.module.scss";

const SideMenu = ({ hasMarks, hasSharing, getPathFunc, list }) => {
  const location = useLocation();
  const [path, setPath] = React.useState("");

  const pathStringsArr = path.split("/");
  const lastSlug = `/${pathStringsArr[pathStringsArr.length - 1]}`;
  const size = useSize(document.querySelector("body"));

  React.useEffect(() => {
    location?.pathname && setPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className={styles.sideMenuWrapper}>
      <div className={styles.menu}>
        {list.map(({ catColor, _key, linkIcon, url, urlLabel }, i) => {
          const path = i && getPathFunc ? `${getPathFunc(url)}` : url;
          const actualPathStringsArr = path.split("/");
          const lastSlugActual = `/${
            actualPathStringsArr[actualPathStringsArr.length - 1]
          }`;

          const className = cx({
            [styles.link]: true,
            "raleway-lg": lastSlug !== lastSlugActual,
            [`raleway-lg-bold ${styles.active}`]: lastSlug === lastSlugActual,
          });

          return (
            <Link className={className} key={_key} url={path}>
              {hasMarks && (
                <span
                  style={{ backgroundColor: catColor || COLORS.pink }}
                  className={styles.mark}
                />
              )}
              {linkIcon?.asset && (
                <img alt="smallIcon" src={linkIcon.asset.url} />
              )}
              {urlLabel}
            </Link>
          );
        })}
      </div>
      {hasSharing && size?.width > BREAKPOINTS.lg && <ShareComponent />}
    </div>
  );
};

SideMenu.propTypes = {
  hasMarks: PropTypes.bool,
  hasSharing: PropTypes.bool,
  getPathFunc: PropTypes.func,
  list: PropTypes.array,
};

SideMenu.defaultProps = {
  hasMarks: false,
  hasSharing: false,
  list: [],
};

export default SideMenu;
