// Modules
import { useMemo } from "react";

// Constants
import COLORS from "../constants/colors";

// Helpers
import generateRandomId from "../helpers/generateRandomId";

const useCategoriesList = (api, categories, mainCategoryName) =>
  useMemo(() => {
    const arr = [
      {
        catColor: COLORS.pink,
        _key: generateRandomId(),
        urlLabel: mainCategoryName ?? "All Categories",
        url: api,
      },
    ];

    for (let key of categories) {
      const obj = {
        catColor: key?.catColor,
        _key: generateRandomId(),
        urlLabel: key.title,
        url: key?._rawSlug?.current || key?.slug.current,
      };
      arr.push(obj);
    }

    return arr;
  }, [api, categories, mainCategoryName]);

export default useCategoriesList;
