import React from "react";

function Facebook({ fill }) {
  return (
    <svg
      id="Group_273"
      data-name="Group 273"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="21.241"
      height="37.537"
      viewBox="0 0 21.241 37.537"
    >
      <path
        id="Path_4333"
        data-name="Path 4333"
        d="M4.694,28.114c0-2.939-.014-5.877.013-8.815.005-.5-.165-.65-.765-.63-1.109.037-2.221,0-3.332.017C.16,18.7-.005,18.593,0,18.2q.034-2.75,0-5.5c0-.353.125-.469.557-.461,1.135.022,2.273-.026,3.407.02.657.027.793-.166.779-.669q-.059-2.093,0-4.188A7.132,7.132,0,0,1,6.571,2.718,8.522,8.522,0,0,1,12.892.067c2.621-.1,5.249-.043,7.874-.066.366,0,.475.1.473.4q-.017,2.782,0,5.564c0,.33-.127.431-.519.426-1.262-.016-2.525-.008-3.786-.005-1.68,0-2.686.718-2.791,2.091-.084,1.1-.023,2.208-.054,3.312-.011.393.272.366.587.366,1.918,0,3.837.016,5.755-.011.563-.008.749.1.684.588-.241,1.843-.449,3.689-.64,5.536-.044.417-.274.5-.728.49-1.513-.019-3.028-.013-4.543-.005-1.088.005-.982-.132-.982.784-.005,5.752-.015,11.5.015,17.255,0,.607-.185.758-.913.748q-3.9-.059-7.8,0c-.732.012-.853-.193-.847-.733.028-2.9.014-5.793.014-8.69"
        transform="translate(0 0)"
        fill={fill || "#a1b9c7"}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Facebook;
