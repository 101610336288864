import React, { useMemo } from "react";
import { graphql } from "gatsby";

// Components
import Layout from "../../components/layout";
import Seo from "../../components/seo/seo";
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import SideMenu from "../../components/sideMenu";
import ExperienceItems from "../../components/experience/experienceItems";

//Hooks
import useCategoriesList from "../../hooks/useCategoriesMenu";

// Styles
import * as styles from "../../templates/blogsStyles.module.scss";
import * as pageStyles from "../../pages/styles.module.scss";

//Constants
import API from "../../constants/api";

const ExperienceCategory = ({ data, pageContext }) => {
  const { content, seo } = data.sanityExperience.tabs;
  const categories = data.allSanityExperienceCategory.nodes;
  const heroTitle = content?.hero?._rawHeroText.titleText;
  const heroSubtitle = content?.hero?._rawHeroText?.subtitleText;
  const filteredContentItems = useMemo(
    () =>
      content.experience.filter(
        (item) => item.category.slug.current === pageContext.slug
      ),
    [pageContext]
  );
  const {
    category: { introText },
  } = filteredContentItems.find((item) => item?.category.introText);

  const categoriesList = useCategoriesList(API.experience, categories);
  const HeroComponent = content?.hero?.isRedesigned ? HeroRedesigned : Hero;

  return (
    <Layout>
      <Seo title={heroTitle} {...seo} />
      <HeroComponent
        image={content.hero._rawBgImage}
        imageClassName={styles.heroImg}
        title={heroTitle}
        subTitle={heroSubtitle}
      />
      {introText && (
        <div className={pageStyles.introWrapper}>
          <p>{introText}</p>
        </div>
      )}
      <div className={styles.mainContentWrapper}>
        <div className={styles.sideMenuWrapper}>
          <SideMenu
            getPathFunc={API.experienceCategory}
            list={categoriesList}
          />
        </div>
        <div className={styles.cardsWrapper}>
          <div className={styles.cards}>
            <ExperienceItems items={filteredContentItems} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    sanityExperience {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          hero {
            _rawBgImage
            _rawHeroText
            isRedesigned
          }
          experience {
            _rawText(resolveReferences: { maxDepth: 10 })
            _rawImage(resolveReferences: { maxDepth: 10 })
            _key
            title
            subtitle
            category {
              introText
              title
              slug {
                current
              }
            }
            slug {
              current
            }
          }
        }
      }
    }
    allSanityExperienceCategory {
      nodes {
        slug {
          current
        }
        title
      }
    }
  }
`;

export default ExperienceCategory;
