import React from "react";

function Post({ fill }) {
  return (
    <svg
      id="Group_275"
      data-name="Group 275"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="47.58"
      height="33.306"
      viewBox="0 0 47.58 33.306"
    >
      <path
        id="Path_4334"
        data-name="Path 4334"
        d="M21.419,17.214a1.7,1.7,0,0,0,2.21,0l19.6-16.8A5.087,5.087,0,0,0,41.226,0H3.826A5.059,5.059,0,0,0,1.82.415Z"
        transform="translate(1.274 -0.694)"
        fill={fill || "#a1b9c7"}
      />
      <path
        id="Path_4335"
        data-name="Path 4335"
        d="M27.119,18.662a5.077,5.077,0,0,1-6.63,0L.6,1.619A5.014,5.014,0,0,0,0,3.967v23.8a5.1,5.1,0,0,0,5.1,5.1H42.5a5.1,5.1,0,0,0,5.1-5.1V3.967A5.014,5.014,0,0,0,47,1.619Z"
        transform="translate(0 0.439)"
        fill={fill || "#a1b9c7"}
      />
    </svg>
  );
}

export default Post;
