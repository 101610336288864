import React from "react";

function Linkedin({ fill }) {
  return (
    <svg
      id="Group_271"
      data-name="Group 271"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="39.42"
      height="37.513"
      viewBox="0 0 39.42 37.513"
    >
      <path
        id="Path_4332"
        data-name="Path 4332"
        d="M0,4.362A4.081,4.081,0,0,1,1.326,1.236,4.866,4.866,0,0,1,4.775,0,4.721,4.721,0,0,1,8.147,1.217,4.274,4.274,0,0,1,9.474,4.476,3.99,3.99,0,0,1,8.185,7.507,4.875,4.875,0,0,1,4.7,8.758H4.661A4.637,4.637,0,0,1,1.288,7.507,4.2,4.2,0,0,1,0,4.362m.493,7.844H8.905V37.52H.493ZM13.566,37.52h8.413V23.385a5.455,5.455,0,0,1,.3-2.046,5.5,5.5,0,0,1,1.611-2.179,4.117,4.117,0,0,1,2.709-.891q4.244,0,4.244,5.722V37.52h8.413V23.006q0-5.608-2.653-8.507A9.049,9.049,0,0,0,29.6,11.6a8.6,8.6,0,0,0-7.617,4.206v.076h-.038l.038-.076v-3.6H13.566q.076,1.213.076,7.541T13.566,37.52"
        transform="translate(0.029 -0.007)"
        fill={fill || "#a1b9c7"}
      />
    </svg>
  );
}

export default Linkedin;
